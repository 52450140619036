<script lang="ts">
    import {icon_settings_account} from "$$assets";

    export let white: boolean = false;
    export let text = '';
</script>


<div class="w-full h-full flex items-center justify-center gap-sm">
    <img class="inline" class:white src={icon_settings_account} alt="loading">
    <span>{@html text}</span>
</div>

<style>
    img {
        animation: rotate 1.5s linear infinite;
    }

    img.white {
        @apply grayscale brightness-0 invert;
    }

    @keyframes rotate {
        to {
            transform: rotate(360deg);
        }
    }
</style>