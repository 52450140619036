<script lang="ts">
    export let block: boolean = false;
    export let disabled: boolean = false;
    export let title: string = '';
    let className: string = 'bg-red';
    export {className as class};
</script>


<button { title } on:click {disabled} class:block class:w-full={block} class="button {className}">
    <slot { disabled }/>
</button>
