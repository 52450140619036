export const settings = {
    PUBLIC_API_SERVER_URL: `//${document.location.hostname}`,
    PUBLIC_API_SERVER_PORT: '7002',
    COLOR: {
        WHITE: '#FFFFFF',
        RED_DARK: '#AC2024',
        RED: '#DB4044',
        CORAL: '#E26669',
        PINK: '#ED9FA1',
        PINK_LIGHT: '#F8D9D9',
        PINK_LIGHT_2: '#FCEBEC',
        WHITE_PINK: '#FDF5F5',
        BLACK: '#484A62',
        GRAY_DARK: '#8790A0',
        GRAY_LIGHT: '#BEC3CE',
        GRAY_LIGHT_2: '#DFE2E9',
        WHITE_GRAY: '#F4F4F4',
        GREEN: '#57C285',
        GREEN_LIGHT: '#EEF4EB',
        BLUE: '#3D55B4',
        BLUE_LIGHT: '#E1E8FD',
        AQUA: '#42A8B0',
        AQUA_LIGHT: '#E8F8F7',
        PURPLE: '#9157B2',
        LILLA: '#F2EAF6',
        YELLOW: '#D5AB23',
        YELLOW_LIGHT: '#FCF7E5',
    },

    BOOKING_STATUS: {
        STATUS_NEW: 10,
        STATUS_ACTIVE: 20,
        STATUS_REJECTED: 30,
        STATUS_CANCELED: 40,
        STATUS_NO_SHOW: 50,
        STATUS_COMPLETED: 60,
        // STATUS_AWAITING_PAYMENT: 6,
        // STATUS_AWAITING_CONFIRMATION: 7
    },

    BOOKING_STATES: {
        IS_VERY_LATE: 'IS_VERY_LATE',
        SHOULD_BE_HERE: 'SHOULD_BE_HERE',
        SHOULD_BE_HERE_SOON: 'SHOULD_BE_HERE_SOON',
        WAITING: 'WAITING',
        MUST_LEAVE: 'MUST_LEAVE',
        SHOULD_LEAVE: 'SHOULD_LEAVE',
        ACTIVE: 'ACTIVE',
        REJECTED: 'REJECTED',
        CANCELED: 'CANCELED',
        NO_SHOW: 'NO_SHOW',
        COMPLETED: 'COMPLETED',
        // AWAITING_PAYMENT: 'AWAITING_PAYMENT',
        // AWAITING_CONFIRMATION: 'AWAITING_CONFIRMATION',
    },
    BOOKING_TIMINGS: {
        IS_LATE_AFTER_MINUTES: 15,
        SHOULD_BE_HERE_SOON_MINUTES: 10,
        GRACE_PERIOD_AFTER_SITING_TIME_MINUTES: 15,
    },
    shifts: [{
        label: 'Όλες', value: '00:00-24:00', all: true
    }, {
        label: 'Πρωί', value: '09:00-13:00'
    }, {
        label: 'Μεσημέρι', value: '13:00-18:00'
    }, {
        label: 'Βράδυ', value: '18:00-24:00'
    }]
        .map(s => {
            const [start, end] = s.value.split('-');
            s.start = start;
            s.end = end;
            return s;
        }),
    BOOKING_CHANNELS: {
        CHANNEL_PHONE: 'Phone',
        CHANNEL_EMAIL: 'Email',
        CHANNEL_SUGGESTION: 'Suggestion',
        CHANNEL_OTHER: 'Other',
    }
    // todo translate these shifts
};


export const hourStops = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30', '24:00'];

export const getRoomDefaultHours = () => ([['11:00', '16:00', 60, true]]);

export const vatCountries = new Map(Object.entries({
    AT: 'AT-Austria',
    BE: 'BE-Belgium',
    BG: 'BG-Bulgaria',
    CY: 'CY-Cyprus',
    CZ: 'CZ-Czechia',
    DE: 'DE-Germany',
    DK: 'DK-Denmark',
    EE: 'EE-Estonia',
    EL: 'EL-Greece',
    ES: 'ES-Spain',
    FI: 'FI-Finland',
    FR: 'FR-France',
    HR: 'HR-Croatia',
    HU: 'HU-Hungary',
    IE: 'IE-Ireland',
    IT: 'IT-Italy',
    LT: 'LT-Lithuania',
    LU: 'LU-Luxembourg',
    LV: 'LV-Latvia',
    MT: 'MT-Malta',
    NL: 'NL-The Netherlands',
    PL: 'PL-Poland',
    PT: 'PT-Portugal',
    RO: 'RO-Romania',
    SE: 'SE-Sweden',
    SI: 'SI-Slovenia',
    SK: 'SK-Slovakia',
    XI: 'XI-Northern Ireland',
}));
