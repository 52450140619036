import {writable} from 'svelte/store';


let myLocale = 'el';
export const locale = writable(myLocale);

export const langs = {
    'el': {
        'flag': '🇬🇷',
        name: 'Ελληνικά'
    },
    'en': {
        flag: '🇬🇧',
        name: 'English'
    },
};

locale.subscribe(value => myLocale = value);
export const getLocale = () => myLocale;


/**
 *
 * @param {string} message
 * @param {string=} group
 * @param {objectType|null} vars
 *
 * @return {string}
 */
export const translate = (message, group = 'global', vars = null) => {
    const lang = getLocale();
    let text = localStorage.getItem(`${lang}:${group}:${message}`) || localStorage.getItem(`${lang}:globals:${message}`) || '';

    if (text == '') {
        text = message;
        lang != 'el' && console.log(`untranslated: ${lang} / ${group} / ${text}`);
    }

    if (vars) {
        Object.keys(vars).map((k) => {
            const regex = new RegExp(`{{${k}}}`, 'g');
            text = text.replace(regex, vars[k]);
        });
    }
    return text;
};

/**
 *
 * @param {string} group
 * @return {function(*, string|null=, objectType|null=): string}
 */
translate.group = group => (message, groupOverride = null, vars = null) => translate(message, groupOverride || group, vars);
translate.locale = () => myLocale;

const getWeekDays = (locale) => {
    const baseDate = new Date(Date.UTC(2017, 0, 1)); // just a Monday
    const weekDays = [];
    for (let i = 0; i < 7; i++) {
        weekDays.push(baseDate.toLocaleDateString(locale, {weekday: 'short'}));
        baseDate.setDate(baseDate.getDate() + 1);
    }
    return weekDays;
};

translate.getDays = () => {
    return {
        el: getWeekDays('el'),
        en: getWeekDays('en'),
    }[myLocale];
};