<script>
    import Route, {router} from '$$Router';
    import LoadingIndicator from '$cmp/primitives/LoadingIndicator.svelte';
    import Cookies from 'js-cookie';
    import {API, loadAdminData, loadUserData, user$} from '$/lib/API/API.js';
    import {locale} from '$/lib/translate';
    import MainPopup from '$cmp/primitives/MainPopup.svelte';
    import {ALERT$} from '$/lib/stores.js';
    import Button from '$cmp/primitives/Button.svelte';
    import {slide} from 'svelte/transition';
    import {noop} from 'svelte/internal';
    import {focusButton} from '$/lib/svelteActions.js';

    let loaded = false;

    const loadTM = async () => {
        return (await import ('./pages/TM.svelte'));
    };
    const loadAPP = async () => {
        return (await import ('./pages/APP.svelte'));
    };
    const loadMANAGE = async () => {
        return (await import ('./pages/MANAGE.svelte'));
    };

    const loc = $router.query?.lang || 'en';

    $locale = loc;

    const initCookieData = pHash => {
        API.key = pHash;
        $user$ = JSON.parse(Cookies.get('tmAppUser') || '');
    };

    setTimeout(async () => {
        const loc = window.location.pathname;
        if (loc.startsWith('/logout')) {
            doLogout();
            return;
        }

        function redirectUser(user) {
            if (!user.isLoggedIn && !loc.startsWith('/tm')) {
                window.location.href = '/tm';
            } else if (user.isLoggedIn && !user.isMain && !loc.startsWith('/manage')) {
                window.location.href = '/manage';
            } else if (user.isLoggedIn && user.isMain) {
                if (!loc.startsWith('/app') && !loc.startsWith('/manage')) {
                    window.location.href = '/app';
                }
            }
        }

        const pHash = Cookies.get('tmAppHash') || '';

        let isLoggedIn = false;
        let isMain = false;

        if (pHash.length > 5) {
            try {
                initCookieData(pHash);
                isLoggedIn = true;
                isMain = $user$.is_main && loc.startsWith('/app');

                if (isMain) {
                    const res = await loadAdminData();
                    if (res == null){
                        doLogout();
                        return;
                    }
                    $user$.client_id = res.client_id;
                    $user$.email = res.email;
                    $user$.first_name = res.first_name;
                    $user$.has_used_trial = res.has_used_trial;
                    $user$.is_main = res.is_main;
                    $user$.mobile = res.mobile;
                } else {
                    await loadUserData();
                }
            } catch (e) {
                dd(e.message); // todo do something here na enimerosei ton papas giati crasare to APP
                Cookies.remove('tmAppHash');
                isLoggedIn = false;
                isMain = false;
                throw (e);
            }
        }

        redirectUser({
            isLoggedIn,
            isMain
        });

        setTimeout(() => {
            loaded = true;
        }, 300);
    });

    const doLogout = () => {
        Cookies.remove('tmAppUser');
        Cookies.remove('tmAppHash');
        document.location.href = '/tm';
        // .set('tmAppUser', JSON.stringify(res.data), cookieOpts);
    };

    const determinePopupAction = () => $ALERT$.isConfirm ? noop : focusButton;

</script>

<!--<FloorEditor/>-->
{#if loaded}
    <Route match="/">
        <Route match="logout">
            {doLogout()}
        </Route>

        <Route match="/manage">
            {#await loadMANAGE()}
                <LoadingIndicator/>
            {:then component}
                <svelte:component this={component.default}/>
            {/await}
        </Route>


        <Route match="/tm">
            {#await loadTM()}
                <LoadingIndicator/>
            {:then component}
                <Route exact redir="/tm/login"/>
                <svelte:component this={component.default}/>
            {/await}
        </Route>

        <Route match="/app">
            {#await loadAPP()}
                <LoadingIndicator/>
            {:then component}
                <Route exact redir="/app/dashboard"/>
                <svelte:component this={component.default}/>
            {/await}
        </Route>
    </Route>

{#if $ALERT$.isVisible}
    <MainPopup on:close={ALERT$.close}>
        <div slot="title" transition:slide={{axis:'y'}}>{$ALERT$.title}</div>
        <div class="py-2" transition:slide={{axis:'y'}}>
            {@html $ALERT$.details}
        </div>
        <div slot="buttons" use:determinePopupAction transition:slide={{axis:'y'}}>
            {#if $ALERT$.isConfirm}
                <Button block on:click={ALERT$.onOk}>Ναι</Button>
                <Button class="mt-xxs secondary" block on:click={ALERT$.close}>Οχι</Button>
            {:else}
                <Button block on:click={ALERT$.close}>OK</Button>
            {/if}
        </div>
    </MainPopup>
{/if}
{/if}