import {settings} from '$/lib/settings';
import {writable} from 'svelte/store';
import {ArrayStore, createStore} from '$/lib/stores.js';
import {identity} from 'svelte/internal';
import {createSearchString} from '$/lib/manageLib.js';


const serverURL = `${settings.PUBLIC_API_SERVER_URL}:${settings.PUBLIC_API_SERVER_PORT}`;

/**
 * sends a public API request
 * @param {string} url
 * @param {object} data
 * @returns {Promise<any>}
 */
export const sendApiCall = async (url, data) => {
    const res = await fetch(`${serverURL}/api-public/${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-TM-MANAGE': 'public'
        },
        body: JSON.stringify(data)
    });

    return await res.json();
};

/**
 * @typedef {Object} partialUserType
 * @property {string} name - The computed name of the user
 * @property {string} first_name - The first name.
 * @property {number} last_name - The last name
 */

const SERVER = `${settings.PUBLIC_API_SERVER_URL}:${settings.PUBLIC_API_SERVER_PORT}`;

export const API = {
    key: '',
    getHeaders: () => {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + API.key,
            'X-TM-MANAGE': document.location.pathname.includes('manage') ? 'manage' : 'app'
        };
    },
    get: async (
        /** @type {string} */ url,
    ) => {
        const res = await fetch(`${SERVER}/api/${url}`, {
            method: 'GET',
            headers: API.getHeaders()
        });

        return await res.json();
    },

    post: async (
        /** @type {string} */ url,
        /** @type {Object} */ data
    ) => {
        const res = await fetch(`${SERVER}/api/${url}`, {
            method: 'POST',
            headers: API.getHeaders(),
            body: JSON.stringify(data),
            credentials: 'omit'
        });

        return await res.json();
    },

    patch: async (
        /** @type {string} */ url,
        /** @type {Object} */ data
    ) => {
        const res = await fetch(`${SERVER}/api/${url}`, {
            method: 'PATCH',
            headers: API.getHeaders(),
            body: JSON.stringify(data)
        });

        return await res.json();
    },

    delete: async (
        /** @type {string} */ url,
    ) => {
        const res = await fetch(`${SERVER}/api/${url}`, {
            method: 'DELETE',
            headers: API.getHeaders()
        });

        return await res.json();
    }
};

/**
 * @param {partialUserType} user
 * @returns {partialUserType}
 */
const userCreationCallback = user => {
    user.name = `${user.first_name} ${user.last_name}`;
    return user;
};


const customerCreationCallback = user => {
    user.__search = createSearchString(user, [
        'first_name',
        'last_name',
        'email',
        'country_code',
        'phone',
    ]);
    return user;
};


/**
 * creates a Map object from an array of objects
 *
 * @param {any[]} arr - the array
 * @returns {Map<any, any>}
 */
export const mapFromArray = arr => {
    const m = new Map();
    arr.forEach(x => m.set(x.id, x));
    return m;
};

export const loadAdminData = async () => {
    const {error, data, message} = await API.get('me');
    if (error) {
        return null;
    }

    restaurants$.set(data.restaurants);
    users$.set(data.users);
    userRestaurants$.set(data.userRestaurants);

    return data;
};

export const loadUserData = async () => {
    const {error, data, message} = await API.get('meManage');
    if (error) {
        return null;
    }

    if (data.restaurants.length === 0) {
        restaurants$.set([]);
        customers$.set([]);
        tags$.set([]);
        return null;
    }
    rooms$.set(data.rooms);
    data.rooms.map(room => {
        const restaurant = data.restaurants.find(r => r.id == room.restaurant_id);
        restaurant.rooms = restaurant.rooms || [];
        restaurant.rooms.push(room);
    });
    restaurants$.set(data.restaurants);
    customers$.set(data.customers);
    tags$.set(data.tags);
    settings$.set({
        defaultRoomId: data.rooms[0].id
    });

    return null;
};


export let user$ = writable();
export let restaurants$ = new ArrayStore([], identity);
export let rooms$ = new ArrayStore([], identity);
export let userRestaurants$ = new ArrayStore([], identity);
export let users$ = new ArrayStore([], userCreationCallback);
export let customers$ = new ArrayStore([], customerCreationCallback);
export let tags$ = new ArrayStore([], customerCreationCallback);

export let settings$ = writable();


export const ROOM$ = createStore();
ROOM$.isOpen = function () {

};
