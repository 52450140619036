import {is_function, noop} from "svelte/internal";

/**
 *
 * @param {HTMLElement} node
 */
export const focusFirstElement = node => {
    return focusFirst(node, '[href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
}

export const focusButton = node => {
    return focusFirst(node, 'button');
};
const focusFirst = (node, query) => {
    const nodes = node.querySelectorAll(query);

    if (nodes && nodes[0]) {
        setTimeout(() => {
            nodes[0].focus();
        });
    }
};

/**
 * @typedef {Object} opts
 * @property {boolean=} allowDecimal
 * @property {string=} default
 *
 *
 * @param {HTMLInputElement} node
 * @param {opts} options
 * @returns {{update: parseOptions, destroy: *}}
 */
export const numericInput = (node, options) => {
    const nums = '0123456789';
    const decimals = '.0123456789';
    let check = nums;

    /** @type {string|null} */
    let defaultValue = null;

    /**
     * @param {opts} opts
     */
    const parseOptions = opts => {
        check = opts.allowDecimal ? decimals : nums;
        if (opts.default !== undefined) {
            defaultValue = opts.default;
        }
    };

    /** @param {KeyboardEvent} event */
    const trapKey = event => {
        const k = event.key;
        if (k.length == 1 && !check.includes(k)) {
            event.preventDefault();
        }
    };

    /** @param {Event} event */
    const trapPaste = event => {
        event.preventDefault();
    };

    const setDefault = () => {
        if (defaultValue === null) return;

        if (node.value == "") {
            node.value = defaultValue;
            setTimeout(() => {
                node.dispatchEvent(new CustomEvent('setDefault', {detail: defaultValue}));
            });
        }
    };


    options && parseOptions(options);


    node.addEventListener("blur", setDefault);
    node.addEventListener("keydown", trapKey);
    node.addEventListener("paste", trapPaste);

    return {
        update: parseOptions,
        destroy: () => {
            node.removeEventListener("keydown", trapKey);
            node.removeEventListener("paste", trapPaste);
            node.removeEventListener("blur", setDefault);
        },
    };
};

/**
 * @param {HTMLInputElement} node
 *
 * @param {Object} options
 * @param {string} options.key - Description of key1.
 * @param {function=} options.callback - Description of key2.
 * @param {boolean=} options.preventDefault - Description of key3.
 * @returns {{update: parseOptions, destroy: *}}
 */
export const trapKey = (node, options) => {
    let key = "Enter";
    let callback = noop();
    let preventDefault = false;


    const parseOptions = opts => {
        if (is_function(opts)) {
            callback = opts;
        } else {
            if (opts.key) {
                key = opts.key;
            }
            if (opts.callback) {
                callback = opts.callback;
            }
            if (opts.preventDefault) {
                preventDefault = opts.preventDefault;
            }
        }
    };

    /**
     *
     * @param {KeyboardEvent} event
     */
    const trapKey = event => {
        console.log(event.key)
        if (event.key === key) {
            if (preventDefault) {
                event.preventDefault();
            }
            callback(node);
        }
    };

    parseOptions(options);
    node.addEventListener("keydown", trapKey);

    return {
        update: parseOptions,
        destroy: () => {
            node.removeEventListener("keydown", trapKey);
        },
    };
};


export function adjustHeight(node) {
    // Get the node's position relative to the viewport
    const rect = node.getBoundingClientRect();

    // Calculate the height
    const height = window.innerHeight - rect.top;

    // Set the node's height
    node.style.height = `${height}px`;

    return {
        // When the window is resized, adjust the height again
        update() {
            const rect = node.getBoundingClientRect();
            const height = window.innerHeight - rect.top;
            node.style.height = `${height}px`;
        },

        // When the action is destroyed, remove the height style
        destroy() {
            node.style.height = '';
        }
    };
}