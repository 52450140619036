export const createSearchString = (object, arrayOfKeys) => {
    return unAccent('|' + arrayOfKeys.flatMap(k => object[k]).join('|').toLowerCase() + '|');
};
export const unAccent = txt => txt.normalize('NFD').replace(/\p{Diacritic}/gu, '');

export const createRequestParams = obj => {
  return Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');
};
