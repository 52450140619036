import {format, roundToNearestMinutesWithOptions} from 'date-fns/fp';
import {getLocale} from '$/lib/translate.js';
import {writable} from 'svelte/store';
import {el} from 'date-fns/locale';


export const createParadox = (error, message, params) => {
    // todo add paradox catcher
    dd('---------- paradox ------------', {
        error, message, params
    }, '---------- paradox ------------');
};

/**
 *
 * @param {Object} o
 * @returns {Object}
 */
export const copyObj = o => JSON.parse(JSON.stringify(o));


/**
 *
 * @param {string} email
 * @returns {boolean}
 */
export const isEmail = email => {
    return /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/.test(email);
};


/**
 *
 * @param {*} val
 * @returns {boolean}
 */
export const isNumeric = val => parseFloat(val) == val;


const LOWERCASE_LETTERS_PATTERN = /[a-z]{1,32}/;
const SPECIAL_CHARACTERS_PATTERN = /[-=_+!@#$%^&*()<>,.:;/?"']{1,32}/;
const UPPERCASE_LETTERS_PATTERN = /[A-Z]{1,32}/;
const NUMBERS_PATTERN = /[0-9]{1,32}/;

/**
 *
 * @param {string} password
 * @returns {boolean}
 */
export const isPassword = password => {
    // Code is more DRY, shorter and might be more performant
    const allPatterns = [LOWERCASE_LETTERS_PATTERN, SPECIAL_CHARACTERS_PATTERN, UPPERCASE_LETTERS_PATTERN, NUMBERS_PATTERN];

    return allPatterns.every(pattern => pattern.test(password));
};


/**
 *
 * @param {Object} obj
 * @returns {Object}
 */
export const trimObject = obj => {
    /**
     *
     * @param {any} key
     * @param {string} value
     * @returns {*|string}
     */
    const replacer = (key, value) => {
        if (typeof value === 'string') {
            return value.trim();
        }
        return value;
    };

    return JSON.parse(JSON.stringify(obj, replacer));
};

// various
/**
 *
 * @param {objectType[]} array - the array to operate
 * @param {*} newItem - the item to replace with
 * @param {string=} key - the key to check against
 * @returns {Object[]}
 */
export const replaceArrayItem = (array, newItem, key = 'id') =>
    array.map(item => (item[key] == newItem[key]) ? newItem : item);

/**
 *
 * @param {CallableFunction} callback
 * @param delay
 * @returns {number}
 */
export const onNextTick = (callback, delay = 0) => setTimeout(callback, delay);

/**
 *
 * @param {String} dt
 * @return {string}
 */
export const DMY = dt => {
    const d = new Date(dt);
    return format('dd/MM/yyyy', d);
};

/**
 *
 * @param {Date} dt
 * @return {string}
 */
export const dateToYMD = dt => {
    if (!(dt instanceof Date)) {
        dt = new Date(dt);
    }
    return format('yyyy-MM-dd', dt);
};

export const dateToUserDateFormat = dt => {
    if (!(dt instanceof Date)) {
        dt = new Date(dt);
    }
    // return dt in format dd/mm, hh:mm using date-fns
    return format('dd/MM, HH:mm', dt);
};

export const dateToHHMM = dt => {
    if (!(dt instanceof Date)) {
        dt = new Date(dt);
    }
    return format('HH:mm', dt);
};
export const dateToYMDHHMM = dt => {
    if (!(dt instanceof Date)) {
        dt = new Date(dt);
    }
    return format('yyyy-MM-dd HH:mm:00', dt);
};
export const dateToFullDateTime = date => {
    return new Date(date).toLocaleDateString(getLocale(), {
        weekday: 'short', day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric'
    });
};
export const dateToFullDate = date => {
    return new Date(date).toLocaleDateString(getLocale(), {
        weekday: 'long', day: 'numeric', month: 'long'
    });
};
export const dateToShortDate = date => {
    return new Date(date).toLocaleDateString(getLocale(), {
        weekday: 'short', day: 'numeric', month: 'short'
    });
};

export const dateToMonthY = date => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    return new Date(date).toLocaleDateString(getLocale(), {
        month: 'short', year: 'numeric'
    });
};

export const dateToNearest = (minutes=15, date) => {
    if (!(date instanceof Date)) {
        date = date ? new Date(date) : new Date();
    }
    return roundToNearestMinutesWithOptions({
        nearestTo: minutes,
        roundingMethod: 'ceil'
    }, date);
};

export const minsToHHmm = mins => {
    const hours = Math.floor(mins / 60);
    const minutes = String(mins % 60);
    let toret;
    if (hours) {
        if (minutes) {
            toret = `${hours}:${minutes.padStart(2, '0')}'`;
        } else {
            toret += `${hours}h`;
        }
    } else {
        toret = `${minutes}'`;
    }

    return toret;
};


export const nameOfDays = () => [...Array(7).keys()].map(i => el.localize.day(i, {width: 'abbreviated'}));

export const logError = e => {

};


export const findItem = (collection, valueToSearch, property = 'id') => {
    return collection.find(item => item[property] == valueToSearch);
};

export const arrayToggle = (array, item) => {
    const s = new Set(array);
    if (s.has(item)) {
        s.delete(item);
    } else {
        s.add(item);
    }
    return [...s];
};


const {subscribe, set} = writable();
export const BOOKING_CHANGE = {
    subscribe,
    setNewBookingItem(item) {
        set(item);
    },
    updateBookings(bookings, item) {
        return bookings.map(booking => {
            return booking.id == item.id ? item : booking;
        });
    }
};
const state = {
    transfer_table_in_progress: {
        booking: null
    }
};
export const state$ = writable(state);