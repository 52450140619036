import {get, writable} from 'svelte/store';
import {noop} from 'svelte/internal';
import {copyObj, dateToYMD} from '$/lib/lib.js';
import {settings} from '$/lib/settings.js';


export function ArrayStore(data, itemCallback) {
    const {subscribe, set, update} = writable(data);

    this.itemCallback = itemCallback;
    this.subscribe = subscribe;
    this.set = function (items) {
        set(items.map(itemCallback));
    };
    this.update = update;
}

ArrayStore.UPDATE = 'updateItem';
ArrayStore.CREATE = 'createItem';

ArrayStore.prototype.createItem = function (newEntry) {
    this.update((oldStore) => {
        oldStore.push(this.itemCallback(newEntry));
        return oldStore;
    });
};

ArrayStore.prototype.updateItem = function (newEntry, col = 'id') {
    this.update((oldStore) => {
        return oldStore.map((item) => {
            return item[col] == newEntry[col] ? this.itemCallback(newEntry) : item;
        });
    });
};

ArrayStore.prototype.findItem = function (id, col = 'id') {
    return get(this).find(item => item[col] == id);
};
/**
 * finds all items that the the `key` equals `value`
 * If `keyToGet` is specified, it returns only this key
 *
 * @param {string} key
 * @param {string|number|boolean} value
 * @param {string?} keyToGet
 * @returns {*[]}
 */
ArrayStore.prototype.itemsWith = function (spec, keyToGet = null) {
    const [key, value] = Object.entries(spec)[0];
    const res = get(this).filter(x => x[key] == value);
    return keyToGet === null ? res : res.map(x => x[keyToGet]);
};

/**
 *
 * @param {Promise<*>} promise
 * @param {ArrayStore|null} store
 * @param {string|null} action
 * @return {Promise<*>}
 */
export const handle = async (promise, store = null, action = null) => {
    const {error, data, message} = await promise;
    if (error) {
        throw new Error(message);
    }
    if (store !== null && action !== null) {
        store[action](data);
    }

    return data;
};

export const showAlert = (title, details, callback = noop) => {
    ALERT$.update(x => {
        return {
            title, details, isVisible: true
        };
    });
    ALERT$.callback = callback;
};

export const showConfirm = (title, details, callback = noop) => {
    ALERT$.update(x => {
        return {
            title, details, isVisible: true, isConfirm: true
        };
    });
    ALERT$.callback = noop;
    ALERT$.onOk = () => {
        callback();
        ALERT$.update(x => {
            x.isVisible = false;
            return x;
        });
    };
};


export const ALERT$ = writable({
    isVisible: false,
    isConfirm: false,
    title: '',
    details: ''
});

ALERT$.close = () => {
    ALERT$.update(x => {
        x.isVisible = false;
        return x;
    });
    ALERT$.callback();
};
ALERT$.onOk = noop();

export const createStore = () => {
    const {subscribe, set, update} = writable(null);
    const o = {
        __data: null,
        get data() {
            return o.__data;
        },
        set(newData) {
            o.__data = newData;
            set(newData);
        },
        update(callback) {
            o.__data = update(callback);
            return o.__data;
        },
        subscribe
    };
    return o;
};


export const SHIFT$ = createStore();
SHIFT$.set(settings.shifts[0]);
SHIFT$.matchNow = () => {
    const now = new Date();
    const nowStr = now.toTimeString().slice(0, 5);
    const shift = SHIFT$.data;
    return shift.start <= nowStr && shift.end >= nowStr;
};


export const calcTimeframe = dt => {
    const now = dateToYMD(new Date());
    const isToday = (now == dt);
    if (isToday) {
        return 'NOW';
    }
    return (dt > now) ? 'FUTURE' : 'PAST';
};


export const TIMEFRAME = writable(calcTimeframe(new Date()));
TIMEFRAME.setTo = date => {
    TIMEFRAME.date = date;
    TIMEFRAME.update(x => calcTimeframe(date));
};
TIMEFRAME.PAST = 'PAST';
TIMEFRAME.FUTURE = 'FUTURE';
TIMEFRAME.NOW = 'NOW';
