import "./app.postcss";
import App from "./App.svelte";
import * as Sentry from "@sentry/svelte";


globalThis.dd = console.log;
globalThis.ddl = txt => {
  const sep = '-'.repeat(20 - txt.length / 2);
  dd(sep, txt, sep)
};
/*
Sentry.init({
  dsn: "https://b9071312fe874e0f4616bac00b942d4e@o467268.ingest.us.sentry.io/4507070922031104",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["t-m.papas", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

*/

const app = new App({
  target: document.getElementById("app"),
});

export default app;
