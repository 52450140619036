<script>
    import {createEventDispatcher} from 'svelte';
    import CloseButton from '$cmp/primitives/CloseButton.svelte';
    import {setContext} from 'svelte';
    import {fade} from 'svelte/transition';
    setContext('closePopup', function(callback) {
        dispatch('close', callback);
    });

    export let showClose = true;
    export let showButton = true;
    export let closeOutside = false;

    const dispatch = createEventDispatcher();
    const close = () => dispatch('close');
    const clickOutside = () => {
        if (closeOutside) {
            dispatch('close');
        }
    }
</script>

<div class="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-30 z-30"  on:click|stopPropagation={clickOutside} transition:fade></div>
<div class="ze-popup" on:click|stopPropagation>
    <div class="flex justify-end h-4">
        {#if showClose}
            <CloseButton on:click={close}/>
        {/if}
    </div>

    <div class="font-bold text-lg -translate-y-6 pointer-events-none pr-xl">
        <slot name="title"></slot>
    </div>

    <div class="max-h-[80vh] overflow-auto">
        <slot/>
    </div>
    {#if showButton}
        <div class="mt-md flex-col gap-sm">
            <slot name="buttons"></slot>
        </div>
    {/if}
</div>

<style lang="postcss">
    .ze-popup {
        @apply p-md bg-white fixed z-30 top-0 left-0 right-0 bottom-0 flex flex-col justify-center;
        @apply  xs:left-1/2  xs:-translate-x-1/2 xs:rounded-lg xs:shadow-md xs:right-auto xs:bottom-auto xs:w-max xs:max-w-96;
        @apply sm:max-w-[600px] sm:min-w-[400px];
        @apply md:min-w-[600px];
    }
</style>